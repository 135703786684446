import {i18n} from '../i18n-setup'

export class AbstractEnumSymbol {
    constructor(name, {value, translation}) {
        this.symbol = Symbol.for(name)
        this.name = name
        this.id = value;
        this.translation = translation

        Object.freeze(this)
    }

    toString() {
        return this.symbol
    }

    getValue() {
        return this.id
    }

    getSymbol() {
        return this.symbol
    }

    getKey() {
        return this.name
    }

    translate() {
        return i18n.t(this.translation)
    }

    get value() {
        return this.getValue()
    }

    get key() {
        return this.getKey()
    }
}

export class AbstractEnum {
    constructor(members) {
        for (let key in members) {
            this[key] = new AbstractEnumSymbol(key, members[key])
        }
        Object.freeze(this)
    }

    members() {
        let members = {}
        for (let key of this.keys()) {
            members[key] = this[key]
        }
        return members
    }

    simpleItems() {
        let items = []
        for (let key of this.keys()) {
            let item = this[key]
            items.push({
                name: item.translation,
                id: item.id
            })
        }
        return items
    }

    keys() {
        return Object.keys(this)
    }

    contains(symbol) {
        if (!(symbol instanceof AbstractEnumSymbol)) return false
        return this[Symbol.keyFor(symbol.symbol)] === symbol
    }

    memberByValue(value, strict = false) {
        for (let key of this.keys()) {
            let member = this[key]
            if (strict && member.value === value) {
                return member
            } else if (!strict && member.value == value) {
                return member
            }
        }

        let className = this.constructor.name
        throw new Error(`Enum '${className}' does not have member of value '${value}'`)
    }
}
